import React, { useEffect } from "react";
import Navbar from "../elements/navbar";
import Footer from "../elements/footer";
import DishCardMenu from "../elements/dishcardmenu";

import Thali from "../images/thali7.jpeg";
import Rajma from "../images/rajma.jpg";
import Paratha from "../images/paratah.jpg";
import Sandwich from "../images/sandwich2.jpg";
import Tea from "../images/tea.jpg";
import Soft from "../images/soft.jpg";
import "../styles/menu.css";
import { useNavigate } from "react-router-dom";
export default function Menu() {
  const navigate = useNavigate();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [navigate]);
  return (
    <div className="homemain">
      <div className="menubg">
        <Navbar page="Menu" />
        <div className="menutop">
          <h1>
            <strong className="whitecolor">Sri Radha Raman</strong>
            <br />
          </h1>
          <p>Our Menu Gallery</p>
        </div>
        <div className="menugrid">
          <div className="dishgrid menudishgrid">
            <DishCardMenu
              name="Thali"
              dishimage={Thali}
              items={[
                [
                  "Pocket Friendly",
                  "7.99",
                  "2 Roti, 1 Sabzi, 1 Rice, 1 Cup Curd",
                ],
                ["Executive", "10.99", "3 Roti, Papad, 1 Cup Curd"],
                [
                  "Champion Thali",
                  "15.99",
                  "4 Roti, 2 Sabzi, 1 Rice, Salad, Papad, Pickle, 1 Cup Curd/ Chai + Sweet",
                ],
              ]}
            />
            <DishCardMenu
              name="Combo"
              dishimage={Rajma}
              items={[
                ["Rajma + Rice", "4.99", "1 Roti"],
                ["Chole + Rice", "5.99", "1 Roti"],
                ["Kadhi + Rice", "4.99", "1 Roti"],
                ["Aloo Puri", "4.99", "4 Puri"],
                ["Sambar Rice", "6.99", "1 Cup Sambar and Rice"],
              ]}
            />
            <DishCardMenu
              name="Parathas + Butter"
              dishimage={Paratha}
              items={[
                ["Aloo Paratha", "9.99", "2 Parathas"],
                ["Gobi Paratha", "11.99", "2 Parathas"],
                ["Paneer Paratha", "11.99", "2 Parathas"],
                ["Mooli Paratha", "11.99", "2 Parathas"],
                ["Chilli Paratha", "9.99", "2 Parathas"],
              ]}
            />
            <DishCardMenu
              name="Sandwich"
              dishimage={Sandwich}
              items={[
                ["Grilled Sandwich", "9.99", "1 Sandwich"],
                ["Cheese Sandwich", "9.99", "1 Sandwich"],
              ]}
            />

            <DishCardMenu
              name="Hot Beverages"
              dishimage={Tea}
              items={[
                ["Chai", "0.99", "1 Cup"],
                ["Masala Chai", "1.49", "1 Cup"],
                ["Masala Coffee", "1.99", "1 Cup"],
                ["Tomato Soup", "2.99", "1 Serving"],
                ["Hot n Sour Soup", "2.99", "1 Serving"],
                ["Sweet Corn Soup", "2.99", "1 Serving"],
              ]}
            />
            <DishCardMenu
              name="Cold Beverages"
              dishimage={Soft}
              items={[
                ["Coke", "3.49", "200ml"],
                ["Pepsi", "3.49", "200ml"],
                ["Fanta", "3.49", "200ml"],
                ["Sprite", "3.49", "200ml"],
              ]}
            />
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}
