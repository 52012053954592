import React from "react";
import Logo from "../images/logo.png";
import "../styles/navbar.css";
import { useNavigate } from "react-router-dom";
export default function Navbar({ page }) {
  const navigate = useNavigate();
  return (
    <div className="navbar">
      <div className="navbarleft">
        <img src={Logo} alt="Logo" onClick={() => navigate("/")} />
      </div>
      <div className="navbarright">
        {page === "Menu" ? (
          <button onClick={() => navigate("/")}>Home</button>
        ) : (
          <button onClick={() => navigate("/menu")}>Menu</button>
        )}
        <button>
          <ion-icon name="call"></ion-icon>
          +353 872228603
        </button>
      </div>
    </div>
  );
}
