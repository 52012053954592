import React from "react";

export default function DishCard({
  dishimage,
  price,
  name,
  description,
  rating,
}) {
  return (
    <div className="dishcard">
      <img src={dishimage} alt="Dish" />
      <div className="dishcardcontent">
        <h3>{name}</h3>
        <p>{description}</p>
      </div>
      <div className="dishcardfooter">
        <p>€{price}</p>
        <div className="dishrating">
          <ion-icon name="star"></ion-icon>
          <p>{rating}</p>
        </div>
      </div>
    </div>
  );
}
