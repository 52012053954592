import React from "react";
import "../styles/footer.css";
import { FaFacebookF } from "react-icons/fa";
import { FaInstagram } from "react-icons/fa";
import { FaTwitter } from "react-icons/fa";
import { SiGooglemaps } from "react-icons/si";
import { FaLinkedin } from "react-icons/fa";
import { useNavigate } from "react-router-dom";
export default function Footer() {
  const navigate = useNavigate();
  return (
    <div className="footer">
      <hr />
      <div className="footerbottom">
        <div className="footerbottomleft">
          &copy;2024 Sri Radha Raman
          <br />
          <a href="https://maps.app.goo.gl/Q7probAySGZTkVto8" target="_blank">
            <SiGooglemaps /> 34, Aungier street D02HK75
          </a>
        </div>
        <div className="footerbottomcenter">
          <ul>
            <li>
              <FaFacebookF />
            </li>
            <li>
              <FaInstagram />
            </li>
            <li>
              <FaTwitter />
            </li>
            <li>
              <FaLinkedin />
            </li>
          </ul>
        </div>
        <div className="footerbottomright">
          <ul>
            <li onClick={() => navigate("/")}>Home</li>
            <li onClick={() => navigate("/menu")}>Menu</li>
          </ul>
        </div>
      </div>
    </div>
  );
}
