import React from "react";

export default function DishCardMenu({
  dishimage,
  items,
  name,

  rating,
  price,
}) {
  return (
    <div className="dishcard">
      <img src={dishimage} alt="Dish" />
      <div className="dishcardcontent">
        <h3 className="orangetext">{name}</h3>
        <ul>
          {items.map((item, index) => (
            <li key={index}>
              <div className="aboutdish">
                <p>{item[0]}</p>
                <p className="orangetext">€{item[1]}</p>
              </div>
              <div className="aboutdishdetails">
                <p>{item[2]}</p>
              </div>
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
}
